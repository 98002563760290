import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Exchange from "./exchange";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import LoginFailed from "./loginFailed";
import CheckYourEmail from "./checkYourEmail";
import EmailLogin from "./emailLogin";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <HelmetProvider>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Toaster
            position="top-right"
            reverseOrder={false}
            gutter={8}
            toastOptions={{
              duration: 5000,
              style: {
                background: "#09090b",
                color: "#fff",
                borderRadius: "10px",
                border: "1px solid #111827",
              },
              className: "maxZIndex",
              success: {
                style: {
                  paddingLeft: "15px",
                },
              },
              error: {
                style: {
                  paddingLeft: "15px",
                },
              },
            }}
          />
          <Routes>
            <Route path="check-your-email" element={<CheckYourEmail />} />
            <Route path="loginFailed" element={<LoginFailed />} />
            <Route path="exchange" element={<Exchange />} />
            <Route path="email-login" element={<EmailLogin />} />
            <Route path="*" element={<App />} />
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </HelmetProvider>
  </Provider>
);
