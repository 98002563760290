import { useEffect, useState } from "react";
import {
  IconBrandX,
  IconBrandGoogle,
  IconBrandFacebook,
  IconBrandApple,
  IconMessage,
  IconArrowNarrowLeft,
} from "@tabler/icons-react";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { cn } from "./utils/cn";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CheckAuth, SubmitEmailLogin } from "./actions/auth";
import { resetAll, selectAuth, setIsAuthenticated } from "./reducers/index";
import { persistor } from "./store/store";
import validator from "validator";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet-async";

const EmailLogin = () => {
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validator.isEmail(email)) {
      setLoading(true);
      SubmitEmailLogin({ email })
        .then((res) => {
          navigate("/check-your-email");
        })
        .catch((err) => {
          setLoading(false);
          toast.error(
            err.response.data
              ? err.response.data.message
              : "An error occurred, please try again"
          );
        });
    } else {
      toast.error("Please enter a valid email address");
    }
  };

  const authState = useSelector(selectAuth);

  const loginWithProvider = (provider) => {
    window.open(`${process.env.REACT_APP_API_HOST}/auth/${provider}`, "_self");
  };

  useEffect(() => {
    if (authState.isAuthenticated) {
      if (authState.returnTo && authState.clientId) {
        CheckAuth({
          clientId: authState.clientId,
          returnTo: authState.returnTo,
        })
          .then((res) => {
            if (res.data.returnTo) {
              persistor.flush();
              dispatch(resetAll());
              setTimeout(() => {
                window.location.href = res.data.returnTo;
              }, 350);
            } else {
              if (authState.returnTo) {
                let goTo = authState.returnTo;
                persistor.flush();
                dispatch(resetAll());
                setTimeout(() => {
                  window.location.href = goTo;
                }, 350);
              }
            }
          })
          .catch((err) => {
            setLoading(false);
            persistor.flush();
            dispatch(setIsAuthenticated({ isAuthenticated: false }));
          });
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [authState]);

  const phoneSignIn = () => {
    // navigate("/phone-login");
    const myPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, 1000);
    });

    toast.promise(myPromise, {
      loading: "Coming Soon!",
      success: "Coming Soon!",
      error: "Coming Soon!",
    });
  };

  return (
    <div className="h-full min-h-screen w-full bg-black bg-dot-white/[0.2] relative flex items-center justify-center">
      <Helmet>
        <title>Sign in with your email | Coracle</title>
      </Helmet>
      <div className="absolute pointer-events-none inset-0 flex items-center justify-center bg-black [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"></div>
      <div className="relative z-20 flex flex-col items-center justify-center max-w-5xl gap-5 px-8 py-8 mx-auto overflow-y-auto">
        <div className="w-full max-w-md p-6 mx-auto bg-white rounded-md md:rounded-2xl md:p-8 shadow-input dark:bg-black border dark:border-white/[0.2] hover:border-slate-700 ease-in-out duration-300">
          <h2 className="text-xl font-bold text-neutral-800 dark:text-neutral-200">
            Sign in with your Email
          </h2>
          <p className="max-w-sm mt-2 text-sm text-neutral-600 dark:text-neutral-300">
            Use your email to sign in or sign up for your favorite institution
          </p>

          <form className="my-8" onSubmit={handleSubmit}>
            <LabelInputContainer className="mb-4">
              <Label htmlFor="email">Email Address</Label>
              <Input
                id="email"
                placeholder="johndoe@acme.com"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                disabled={loading}
              />
            </LabelInputContainer>

            <button
              className="bg-gradient-to-br relative group/btn from-black dark:from-zinc-900 dark:to-zinc-900 to-neutral-600 block dark:bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset] dark:shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset] disabled:cursor-not-allowed disabled:opacity-50"
              type="submit"
              disabled={loading}
            >
              {loading ? "Processing..." : <>Sign In &rarr;</>}
              <BottomGradient />
            </button>

            <div className="bg-gradient-to-r from-transparent via-neutral-300 dark:via-neutral-700 to-transparent my-8 h-[1px] w-full" />

            <div className="flex flex-col space-y-4">
              <button
                className="relative group/btn flex space-x-2 items-center justify-start px-4 w-full text-black rounded-md h-10 font-medium shadow-input bg-gray-50 dark:bg-zinc-900 dark:shadow-[0px_0px_1px_1px_var(--neutral-800)] disabled:cursor-not-allowed disabled:opacity-50"
                type="button"
                onClick={() => loginWithProvider("google")}
                disabled={loading}
              >
                <IconBrandGoogle className="w-4 h-4 text-neutral-800 dark:text-neutral-300" />
                <span className="text-sm text-neutral-700 dark:text-neutral-300">
                  Sign in with Google
                </span>
                <BottomGradient />
              </button>
              <button
                className="relative group/btn flex space-x-2 items-center justify-start px-4 w-full text-black rounded-md h-10 font-medium shadow-input bg-gray-50 dark:bg-zinc-900 dark:shadow-[0px_0px_1px_1px_var(--neutral-800)] disabled:cursor-not-allowed disabled:opacity-50"
                type="button"
                onClick={() => loginWithProvider("facebook")}
                disabled={loading}
              >
                <IconBrandFacebook className="w-4 h-4 text-neutral-800 dark:text-neutral-300" />
                <span className="text-sm text-neutral-700 dark:text-neutral-300">
                  Sign in with Facebook
                </span>
                <BottomGradient />
              </button>
              <button
                className="relative group/btn flex space-x-2 items-center justify-start px-4 w-full text-black rounded-md h-10 font-medium shadow-input bg-gray-50 dark:bg-zinc-900 dark:shadow-[0px_0px_1px_1px_var(--neutral-800)] disabled:cursor-not-allowed disabled:opacity-50"
                type="button"
                onClick={() => loginWithProvider("twitter")}
                disabled={loading}
              >
                <IconBrandX className="w-4 h-4 text-neutral-800 dark:text-neutral-300" />
                <span className="text-sm text-neutral-700 dark:text-neutral-300">
                  Sign in with X / Twitter
                </span>
                <BottomGradient />
              </button>
              <button
                className="relative group/btn flex space-x-2 items-center justify-start px-4 w-full text-black rounded-md h-10 font-medium shadow-input bg-gray-50 dark:bg-zinc-900 dark:shadow-[0px_0px_1px_1px_var(--neutral-800)] disabled:cursor-not-allowed disabled:opacity-50"
                type="button"
                onClick={() => loginWithProvider("apple")}
                disabled={loading}
              >
                <IconBrandApple className="w-4 h-4 text-neutral-800 dark:text-neutral-300" />
                <span className="text-sm text-neutral-700 dark:text-neutral-300">
                  Sign in with Apple
                </span>
                <BottomGradient />
              </button>
              <button
                className="relative group/btn flex space-x-2 items-center justify-start px-4 w-full text-black rounded-md h-10 font-medium shadow-input bg-gray-50 dark:bg-zinc-900 dark:shadow-[0px_0px_1px_1px_var(--neutral-800)] disabled:cursor-not-allowed disabled:opacity-50"
                type="button"
                onClick={() => phoneSignIn()}
                disabled={loading}
              >
                <IconMessage className="w-4 h-4 text-neutral-800 dark:text-neutral-300" />
                <span className="text-sm text-neutral-700 dark:text-neutral-300">
                  Sign in with Phone
                </span>
                <BottomGradient />
              </button>
              <button
                className="relative group/btn flex space-x-2 items-center justify-start px-4 w-full text-black rounded-md h-10 font-medium shadow-input bg-gray-50 dark:bg-zinc-900 dark:shadow-[0px_0px_1px_1px_var(--neutral-800)]"
                type="button"
                onClick={() => navigate("/")}
                disabled={loading}
              >
                <IconArrowNarrowLeft className="w-4 h-4 text-neutral-800 dark:text-neutral-300" />
                <span className="text-sm text-neutral-700 dark:text-neutral-300">
                  Go Back
                </span>
                <BottomGradient />
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="absolute bottom-0 left-auto right-auto flex flex-col items-center justify-center w-full gap-1 p-4 text-xs text-zinc-300 xl:flex-row xl:justify-between xl:max-w-[1280px] xl:px-5 2xl:px-0">
        <div className="flex items-center justify-center gap-4">
          <p className="text-center">
            All Rights Reserved &copy;{" "}
            <a
              href="https://getcoracle.com"
              target="_blank"
              rel="noreferrer"
              className="transition-all duration-200 hover:text-white"
            >
              Coracle
            </a>{" "}
            {new Date().getFullYear()}
          </p>
          <p className="text-center">
            Powered by{" "}
            <a
              href="https://hyperauth.app"
              target="_blank"
              rel="noreferrer"
              className="transition-all duration-200 hover:text-[#f20c2e]"
            >
              HyperAuth
            </a>
          </p>
        </div>
        <div className="flex items-center justify-center gap-4">
          <a
            href="https://getcoracle.com/legal/terms-of-use"
            target="_blank"
            rel="noreferrer"
            className="transition-all duration-200 hover:text-white"
          >
            Terms of Use
          </a>
          <a
            href="https://getcoracle.com/legal/privacy-policy"
            target="_blank"
            rel="noreferrer"
            className="transition-all duration-200 hover:text-white"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
};

const BottomGradient = () => {
  return (
    <>
      <span className="absolute inset-x-0 block w-full h-px transition duration-500 opacity-0 group-hover/btn:opacity-100 -bottom-px bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="absolute block w-1/2 h-px mx-auto transition duration-500 opacity-0 group-hover/btn:opacity-100 blur-sm -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};

const LabelInputContainer = ({ children, className }) => {
  return (
    <div className={cn("flex flex-col space-y-2 w-full", className)}>
      {children}
    </div>
  );
};

export default EmailLogin;
