import toast from "react-hot-toast";
import { CheckAuth, DryAuth, Logout } from "./actions/auth";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { persistor } from "./store/store";
import { AnimatePresence, motion } from "framer-motion";
import { cn } from "./utils/cn";
import GoogleLogo from "./assets/google";
import AppleLogo from "./assets/apple";
import FacebookLogo from "./assets/facebook";
import TwitterLogo from "./assets/twitter";
import EmailLogo from "./assets/at-line";
import PhoneLogo from "./assets/phone";
import Loading from "./loading";
import { useDispatch, useSelector } from "react-redux";
import {
  setClientId,
  setReturnTo,
  resetAll,
  selectAuth,
  setIsAuthenticated,
} from "./reducers/index";
import CoracleLogo from "./assets/coracleLogo";
import AMHLogo from "./assets/amhLogo";
import { Helmet } from "react-helmet-async";

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authState = useSelector(selectAuth);

  const projects = [
    {
      title: "Sign in with Google",
      description: "Access Coracle by signing in with your Google account.",
      link: "loginWithGoogle",
      icon: <GoogleLogo />,
      action: () => loginWithProvider("google"),
    },
    {
      title: "Sign in with Facebook",
      description: "Access Coracle by signing in with your Facebook account.",
      link: "loginWithFacebook",
      icon: <FacebookLogo />,
      action: () => loginWithProvider("facebook"),
    },
    {
      title: "Sign in with Apple",
      description: "Access Coracle by signing in with your Apple account.",
      link: "loginWithApple",
      icon: <AppleLogo />,
      action: () => loginWithProvider("apple"),
    },
    {
      title: "Sign in with X / Twitter",
      description:
        "Access Coracle by signing in with your Twitter / X account.",
      link: "loginWithTwitter",
      icon: <TwitterLogo />,
      action: () => loginWithProvider("twitter"),
    },
    {
      title: "Sign in with email",
      description: "Access Coracle by signing in with your email.",
      link: "loginWithEmail",
      icon: <EmailLogo />,
      action: () => emailSignIn(),
    },
    {
      title: "Sign in with your Phone",
      description: "Access Coracle by signing in with your phone number.",
      link: "loginWithPhone",
      icon: <PhoneLogo />,
      action: () => phoneSignIn(),
    },
  ];

  const institutions = [
    {
      title: "Continue to Coracle",
      description: "Continue to the main Coracle application.",
      link: "continueToCoracle",
      icon: <CoracleLogo />,
      action: () => window.open("https://getcoracle.com", "_self"),
    },
    {
      title: "Augusta Museum of History",
      description: "Continue to the Augusta Museum of History.",
      link: "continueToAMH",
      icon: <AMHLogo />,
      action: () => window.open("https://augusta.museum", "_self"),
    },
  ];

  const HoverEffect = ({ items, className }) => {
    let [hoveredIndex, setHoveredIndex] = useState(null);

    return (
      <div
        className={cn(
          items.length % 3 === 0
            ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-10"
            : "grid grid-cols-1 md:grid-cols-2 py-10",
          className
        )}
      >
        {items.map((item, idx) => (
          <button
            key={item?.link}
            className="relative block w-full h-full p-2 group"
            onMouseEnter={() => setHoveredIndex(idx)}
            onMouseLeave={() => setHoveredIndex(null)}
            onClick={item.action}
          >
            <AnimatePresence>
              {hoveredIndex === idx && (
                <motion.span
                  className="absolute inset-0 h-full w-full bg-neutral-200 dark:bg-slate-800/[0.8] block rounded-3xl"
                  layoutId="hoverBackground"
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    transition: { duration: 0.15 },
                  }}
                  exit={{
                    opacity: 0,
                    transition: { duration: 0.15, delay: 0.2 },
                  }}
                />
              )}
            </AnimatePresence>
            <Card>
              <div className="flex items-center justify-start gap-3 mt-2">
                {item.icon}
                <CardTitle>{item.title}</CardTitle>
              </div>
              <CardDescription className="w-full text-left">
                {item.description}
              </CardDescription>
            </Card>
          </button>
        ))}
      </div>
    );
  };

  const Card = ({ className, children }) => {
    return (
      <div
        className={cn(
          "rounded-2xl h-full w-full p-4 overflow-hidden bg-black border border-transparent dark:border-white/[0.2] group-hover:border-slate-700 relative z-20",
          className
        )}
      >
        <div className="relative z-50">
          <div className="p-4">{children}</div>
        </div>
      </div>
    );
  };

  const CardTitle = ({ className, children }) => {
    return (
      <h4 className={cn("text-zinc-100 font-bold tracking-wide", className)}>
        {children}
      </h4>
    );
  };

  const CardDescription = ({ className, children }) => {
    return (
      <p
        className={cn(
          "mt-6 text-zinc-400 tracking-wide leading-relaxed text-sm",
          className
        )}
      >
        {children}
      </p>
    );
  };
  const [loading, setLoading] = useState(true);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    let searchClientId = searchParams.get("clientId");
    let searchReturnTo = searchParams.get("returnTo");
    if (searchClientId) {
      dispatch(setClientId({ clientId: searchClientId }));
    }
    if (searchReturnTo) {
      dispatch(setReturnTo({ returnTo: searchReturnTo }));
    }
    if (searchClientId && searchReturnTo) {
      CheckAuth({ clientId: searchClientId, returnTo: searchReturnTo })
        .then((res) => {
          if (res.data.returnTo) {
            persistor.flush();
            dispatch(resetAll());
            setTimeout(() => {
              window.location.href = res.data.returnTo;
            }, 350);
          } else {
            if (authState.returnTo) {
              let goTo = authState.returnTo;
              persistor.flush();
              dispatch(resetAll());
              setTimeout(() => {
                window.location.href = goTo;
              }, 350);
            }
            setTimeout(() => setLoading(false), 550);
          }
        })
        .catch((err) => {
          dispatch(setIsAuthenticated({ isAuthenticated: false }));
          setTimeout(() => setLoading(false), 550);
        });
    } else {
      if (authState.returnTo && authState.clientId) {
        CheckAuth({
          clientId: authState.clientId,
          returnTo: authState.returnTo,
        })
          .then((res) => {
            if (res.data.returnTo) {
              persistor.flush();
              dispatch(resetAll());
              setTimeout(() => {
                window.location.href = res.data.returnTo;
              }, 350);
            } else {
              if (authState.returnTo) {
                let goTo = authState.returnTo;
                persistor.flush();
                dispatch(resetAll());
                setTimeout(() => {
                  window.location.href = goTo;
                }, 350);
              }
              setLoading(false);
            }
          })
          .catch((err) => {
            dispatch(setIsAuthenticated({ isAuthenticated: false }));
            setLoading(false);
          });
      } else {
        setTimeout(() => setLoading(false), 550);
      }
    }
    // eslint-disable-next-line
  }, [authState.clientId, authState.returnTo, searchParams]);

  useEffect(() => {
    DryAuth()
      .then((res) => {
        if (res.data.success) {
          dispatch(setIsAuthenticated({ isAuthenticated: true }));
        } else {
          dispatch(setIsAuthenticated({ isAuthenticated: false }));
        }
      })
      .catch((err) => {
        dispatch(setIsAuthenticated({ isAuthenticated: false }));
      });
  }, []);

  const emailSignIn = () => {
    navigate("/email-login");
  };

  const phoneSignIn = () => {
    // navigate("/phone-login");
    const myPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, 1000);
    });

    toast.promise(myPromise, {
      loading: "Coming Soon!",
      success: "Coming Soon!",
      error: "Coming Soon!",
    });
  };

  const loginWithProvider = (provider) => {
    window.open(`${process.env.REACT_APP_API_HOST}/auth/${provider}`, "_self");
  };

  const logout = () => {
    Logout()
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
          dispatch(resetAll());
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return loading ? (
    <Loading />
  ) : (
    <div className="h-full min-h-screen w-full bg-black bg-dot-white/[0.2] relative flex items-center justify-center">
      <Helmet>
        <title>Sign in | Coracle</title>
      </Helmet>
      <div className="absolute pointer-events-none inset-0 flex items-center justify-center bg-black [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"></div>
      <div className="relative z-20 flex flex-col items-center justify-center max-w-5xl gap-5 px-8 py-8 mx-auto overflow-y-auto">
        {authState.isAuthenticated ? (
          <>
            <p className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-b from-neutral-50 to-zinc-400">
              Successfully Signed In
            </p>
            <p className="text-xl font-medium text-transparent bg-clip-text bg-gradient-to-b from-neutral-50 to-zinc-400">
              Select an institution to continue
            </p>
            <HoverEffect items={institutions} />
          </>
        ) : (
          <>
            <p className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-b from-neutral-50 to-zinc-400">
              Sign in to continue
            </p>
            <HoverEffect items={projects} />
          </>
        )}
      </div>
      <div className="absolute bottom-0 left-auto right-auto flex flex-col items-center justify-center w-full gap-1 p-4 text-xs text-zinc-300 xl:flex-row xl:justify-between xl:max-w-[1280px] xl:px-5 2xl:px-0">
        <div className="flex items-center justify-center gap-4">
          <p className="text-center">
            All Rights Reserved &copy;{" "}
            <a
              href="https://getcoracle.com"
              target="_blank"
              rel="noreferrer"
              className="transition-all duration-200 hover:text-white"
            >
              Coracle
            </a>{" "}
            {new Date().getFullYear()}
          </p>
          <p className="text-center">
            Powered by{" "}
            <a
              href="https://hyperauth.app"
              target="_blank"
              rel="noreferrer"
              className="transition-all duration-200 hover:text-[#f20c2e]"
            >
              HyperAuth
            </a>
          </p>
        </div>
        <div className="flex items-center justify-center gap-4">
          <a
            href="https://getcoracle.com/legal/terms-of-use"
            target="_blank"
            rel="noreferrer"
            className="transition-all duration-200 hover:text-white"
          >
            Terms of Use
          </a>
          <a
            href="https://getcoracle.com/legal/privacy-policy"
            target="_blank"
            rel="noreferrer"
            className="transition-all duration-200 hover:text-white"
          >
            Privacy Policy
          </a>
          {authState.isAuthenticated && (
            <p
              onClick={() => logout()}
              className="transition-all duration-200 cursor-pointer hover:text-white"
            >
              Logout
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default App;
