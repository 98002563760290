import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clientId: null,
  returnTo: null,
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setClientId: (state, action) => {
      console.log(action);
      state.clientId = action.payload.clientId;
    },
    setReturnTo: (state, action) => {
      state.returnTo = action.payload.returnTo;
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated;
    },
    receiveEvent: (state, action) => {
      state.returnTo = action.payload.returnTo;
      state.clientId = action.payload.clientId;
    },
    resetAll: (state, action) => {
      state.clientId = null;
      state.returnTo = null;
      state.isAuthenticated = false;
      localStorage.removeItem("persist:coraclesso");
    },
  },
});

export const selectAuth = (state) => ({ clientId: state.clientId, returnTo: state.returnTo, isAuthenticated: state.isAuthenticated });
export const { setClientId, setReturnTo, setIsAuthenticated, receiveEvent, resetAll } = authSlice.actions;
export default authSlice.reducer;
