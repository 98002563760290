import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

const LoginFailed = () => {
  const navigate = useNavigate();
  return (
    <div className="h-full min-h-screen w-full bg-black bg-dot-white/[0.2] relative flex items-center justify-center">
      <Helmet>
        <title>Login Failed | Coracle</title>
      </Helmet>
      <div className="absolute pointer-events-none inset-0 flex items-center justify-center bg-black [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"></div>
      <div className="relative z-20 flex flex-col items-center justify-center max-w-5xl gap-5 px-8 py-8 mx-auto overflow-y-auto">
        <p className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-b from-neutral-50 to-zinc-400">
          Login Failed
        </p>
        <p className="text-xl font-medium text-center text-transparent bg-clip-text bg-gradient-to-b from-neutral-50 to-zinc-400">
          The link you used is invalid or expired. You can{" "}
          <span
            className="underline transition-all duration-300 cursor-pointer decoration-slate-600 underline-offset-2 decoration-1 hover:decoration-blue-500"
            onClick={() => navigate("/")}
          >
            go back and try again
          </span>
          , try a{" "}
          <span
            className="underline transition-all duration-300 cursor-pointer decoration-slate-600 underline-offset-2 decoration-1 hover:decoration-blue-500"
            onClick={() => navigate("/")}
          >
            different login method
          </span>
          , or{" "}
          <a
            href="https://getcoracle.com/contact-us"
            target="_blank"
            rel="noreferrer"
            className="underline transition-all duration-300 cursor-pointer decoration-slate-600 underline-offset-2 decoration-1 hover:decoration-blue-500"
          >
            contact support
          </a>
          .
        </p>
      </div>
      <div className="absolute bottom-0 left-auto right-auto flex flex-col items-center justify-center w-full gap-1 p-4 text-xs text-zinc-300 xl:flex-row xl:justify-between xl:max-w-[1280px] xl:px-5 2xl:px-0">
        <div className="flex items-center justify-center gap-4">
          <p className="text-center">
            All Rights Reserved &copy;{" "}
            <a
              href="https://getcoracle.com"
              target="_blank"
              rel="noreferrer"
              className="transition-all duration-200 hover:text-white"
            >
              Coracle
            </a>{" "}
            {new Date().getFullYear()}
          </p>
          <p className="text-center">
            Powered by{" "}
            <a
              href="https://hyperauth.app"
              target="_blank"
              rel="noreferrer"
              className="transition-all duration-200 hover:text-[#f20c2e]"
            >
              HyperAuth
            </a>
          </p>
        </div>
        <div className="flex items-center justify-center gap-4">
          <a
            href="https://getcoracle.com/legal/terms-of-use"
            target="_blank"
            rel="noreferrer"
            className="transition-all duration-200 hover:text-white"
          >
            Terms of Use
          </a>
          <a
            href="https://getcoracle.com/legal/privacy-policy"
            target="_blank"
            rel="noreferrer"
            className="transition-all duration-200 hover:text-white"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginFailed;
