import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { ExchangeCode } from "./actions/auth";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet-async";

const Exchange = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const code = searchParams.get("exchangeId");
    if (code && code.length > 0) {
      ExchangeCode({ exchangeCode: code })
        .then((res) => {
          toast.success("Successfully authenticated");
          navigate("/");
        })
        .catch((err) => {
          toast.error("Authentication failed");
          navigate("/");
        });
    } else {
      navigate("/");
    }
  }, []);
  return (
    <div className="flex items-center justify-center w-full h-screen bg-slate-900">
      <Helmet>
        <title>Processing | Coracle</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center px-16 py-6 bg-white shadow-lg rounded-xl">
        <p className="text-lg font-semibold leading-none uppercase animate-pulse">
          Processing
        </p>
        <p onClick={() => navigate("/")}>GO BACK</p>
      </div>
    </div>
  );
};

export default Exchange;
