const CoracleLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 309.9498 346.0575"
      className="w-6 h-6"
    >
      <path
        fill="#ffffff"
        d="M0 172.5603C0 79.7109 71.7398 0 178.1855 0c44.5437 0 90.9684 15.0052 131.7643 47.8266l-30.0105 68.9325C260.2423 53.4553 219.9183 8.9116 178.6575 8.9116c-60.965 0-111.6024 72.6803-111.6024 174.8991 0 89.5665 46.8896 147.7136 102.2188 147.7136 40.331 0 83.0008-33.7653 106.9176-91.9124l20.6269 75.9631c-34.2303 20.634-74.0893 30.4825-113.9412 30.4825C73.1488 346.0575 0 268.2204 0 172.5603Z"
      />
      <path
        fill="#ffffff"
        d="M185.4385 175.5392c0-14.2676 10.0215-27.3766 28.5293-27.3766s28.5351 13.109 28.5351 27.3766-9.6391 27.3766-28.5351 27.3766c-18.5079 0-28.5293-13.4971-28.5293-27.3766Z"
      />
    </svg>
  );
};

export default CoracleLogo;
