import axiosInstance from "./axios";

export const CheckAuth = (data) => {
  return axiosInstance.put("/auth/status", data);
};

export const DryAuth = (data) => {
  return axiosInstance.get("/auth/dryAuth");
};

export const BasicLogin = (data) => {
  return axiosInstance.post("/login", data);
};

export const Logout = () => {
  return axiosInstance.get("/auth/logout");
};

export const ExchangeCode = (data) => {
  return axiosInstance.post("/auth/exchangeCode", data, {
    headers: { Authorization: `Bearer ${data.exchangeCode}` },
  });
};

export const SubmitEmailLogin = (data) => {
  return axiosInstance.post("/auth/email", data);
};
