import axios from "axios";

let api_url = process.env.REACT_APP_API_HOST || "http://localhost:3001";

const axiosInstance = axios.create({
  baseURL: api_url,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

// axiosInstance.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   async (err) => {
//     if (err.response.status === 440) {
//       window.location.href = "/login";
//     } else {
//       return Promise.reject(err);
//     }
//     return Promise.reject(err);
//   }
// );

export default axiosInstance;
