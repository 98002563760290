const AMHLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 190 191"
      className="w-6 h-6"
    >
      <path
        fill="#fff"
        d="M92.33 0s-8.14 8.18-2 14.33c.33.33-2.33 1.33-2.33 1.33v2l1.67 1s-.67 16.67-14 18c0 0-7.33 6.33 3 9 0 0 9 17-12 19.67L66.33 70S52.67 68.33 53 78.67c0 0-2 7 8.33 9 0 0-3.67 9 6 8.33 0 0 12.67 8.33 10.33 30.33 0 0-10.67-4.33-2.33 6.33 0 0 1.67 6-.33 9.67 0 0 1.33 6.67 7.67 7.67v2s-3.67 1 2 4.67c0 0 7.33 14-9.67 20.67 0 0-6.67.67-2.33 5l-4 .33V191H0V0h92.33Zm5.34 0s8.14 8.18 2 14.33c-.33.33 2.33 1.33 2.33 1.33v2l-1.67 1s.67 16.67 14 18c0 0 7.33 6.33-3 9 0 0-9 17 12 19.67l.33 4.67s13.67-1.67 13.33 8.67c0 0 2 7-8.33 9 0 0 3.67 9-6 8.33 0 0-12.67 8.33-10.33 30.33 0 0 10.67-4.33 2.33 6.33 0 0-1.67 6 .33 9.67 0 0-1.33 6.67-7.67 7.67v2s3.67 1-2 4.67c0 0-7.33 14 9.67 20.67 0 0 6.67.67 2.33 5l4 .33V191H190V0H97.67Z"
      />
    </svg>
  );
};
export default AMHLogo;
